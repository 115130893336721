<template>
  <div>
    <section>
      <article class="container text-left">
        <h1>Dashboard</h1>


        <div class="cards-grid">
          <div
            @click="$router.push({path: '/ovalspace/os-admin/categories'})"
          >
            <div class="card-details">
              <h2>Room Categories</h2>
              <p class="total">{{ room_categories }}</p>
              <p class="text"><span class="span-text">The number of Room Categories.</span></p>
            </div>
            <div class="card-icon icon1"><i class="el-icon-s-grid"></i></div>
          </div>
          <div
            @click="$router.push({path: '/ovalspace/os-admin/reservations'})"
          >
            <div class="card-details">
              <h2>Reservations</h2>
              <p class="total">{{ reservations }}</p>
              <p class="text"><span class="span-text">Total Active Reservations</span></p>
            </div>
            <div class="card-icon icon2"><i class="el-icon-s-check"></i></div>
          </div>
          <div
            @click="$router.push({path: '/ovalspace/os-admin/bookings'})"
          >
            <div class="card-details">
              <h2>Bookings</h2>
              <p class="total">{{ bookings }}</p>
              <p class="text"><span class="span-text">Active Bookings present</span></p>
            </div>
            <div class="card-icon icon3"><i class="el-icon-check"></i></div>
          </div>
          <div
            @click="$router.push({path: '/ovalspace/os-admin/guest-feedbacks'})"
          >
            <div class="card-details">
              <h2>Feedbacks</h2>
              <p class="total">{{ feedbacks }}</p>
              <p class="text"><span class="span-text">Number of guest room feedbacks</span></p>
            </div>
            <div class="card-icon icon4"><i class="el-icon-chat-line-round"></i></div>
          </div>
          <div
            @click="$router.push({path: '/ovalspace/os-admin/blog'})"
          >
            <div class="card-details">
              <h2>Blog</h2>
              <p class="total">{{ blog }}</p>
              <p class="text"><span class="span-text">The number of blog posts added</span></p>
            </div>
            <div class="card-icon icon4"><i class="el-icon-message"></i></div>
          </div>
          <div
            @click="$router.push({path: '/ovalspace/os-admin/users'})"
          >
            <div class="card-details">
              <h2>Team</h2>
              <p class="total">{{ admin_users }}</p>
              <p class="text"><span class="span-text">The number of team of people at the Lodge</span></p>
            </div>
            <div class="card-icon icon4"><i class="el-icon-s-custom"></i></div>
          </div>
        </div>

        <section>
          <article class="calendar-component">
            <div
              @click="$router.push({path: '/ovalspace/os-admin/general-calendar'})"
            >
              <div class="card-details">
                <h2>Calendar</h2>
                <p class="total">Calendar</p>
                <p>
                  Our booking calendar provides a comprehensive overview of all upcoming reservations, making it easy to manage room availability and customer appointments. With clear dates and times, it ensures seamless scheduling, avoids conflicts, and allows quick updates. Stay organized and ensure a smooth experience for clients with real-time calendar updates.
                </p>
              </div>
              <div class="card-icon icon1"><i class="el-icon-date"></i></div>
            </div>
          </article>
        </section>
      </article>
    </section>
    <!-- <GraphStatisticsComponent></GraphStatisticsComponent> -->
  </div>
</template>

<script>
// import GraphStatisticsComponent from "../../components/backoffice/graph-statistics.vue";
export default {
  components: {
    // GraphStatisticsComponent,
  },
  data() {
    return {
      reservations: "",
      bookings: "",
      room_categories: "",
      feedbacks: "",
      admin_users: "",
      blog: "",
    };
  },

  mounted() {
    this.getStatistics();
  },

  methods: {
    
    
    async getStatistics() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`dashboard`);
        if (request.data.success) {
          this.reservations = request.data.reservations;
          this.bookings = request.data.bookings;
          this.room_categories = request.data.room_categories;
          this.feedbacks = request.data.feedbacks;
          this.admin_users = request.data.admin_users;
          this.blog = request.data.blog;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch sizes now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>

<style scoped>
h1 {
  font-size: 1.8em;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 30px;
}
.cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.cards-grid > div {
  min-height: 150px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cards-grid > div .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 15px;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards-grid > div .icon1 {
  background-color: rgba(128, 0, 128, 0.5);
  color: purple;
}
.cards-grid > div .icon2 {
  background-color: rgba(255, 165, 0, 0.5);
  color: orange;
}
.cards-grid > div .icon3 {
  background-color: rgba(0, 128, 0, 0.5);
  color: green;
}
.cards-grid > div .icon4 {
  background-color: rgba(165, 42, 42, 0.5);
  color: brown;
}
.span-text {
  color: rgba(0, 128, 0, 1);
}
.card-details {
  padding: 15px;
}
.card-details h2 {
  font-size: 1em;
  font-weight: 600;
  color: grey;
  padding: 0px;
  margin: 0px;
}
.card-details .total {
  font-size: 1.5em;
  font-weight: 600;
}
.card-details .text {
  font-size: 0.8em;
  padding: 0px;
  margin: 0px;
}

.calendar-component {
  margin-top: 50px;
  min-height: 150px;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.calendar-component .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 15px;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-component .icon1 {
  background-color: rgba(128, 0, 128, 0.5);
  color: purple;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
}
</style>